import BaseAPI from '../base'

function getOrderWidget(order_id, mode) {
    if (mode == 'preorder') {
        return BaseAPI.sendPost(`payment/preorder_p2p/${order_id}`);
    } else if (mode == 'reserve') {
        return BaseAPI.sendPost(`payment/reserve_p2p/${order_id}`);
    } else {
        return BaseAPI.sendPost(`payment/p2p/${order_id}`);   
    }
}

async function getInsideWidget(order_id) {
    return BaseAPI.sendPost(`payment/p2p/${order_id}`);   
}

async function getPreorderWidget(order_id, split_index) {
    return BaseAPI.sendPost(`payment/preorder_p2p/${order_id}`);
}

async function getReserveWidget(order_id) {
    return BaseAPI.sendPost(`payment/reserve_p2p/${order_id}`);
}

async function getHotelsWidget(order_id,) {
    return BaseAPI.sendPost(`payment/hotels_widget/${order_id}`);
}

async function getCartSplits(cafe_id, mode, cart) {
    return BaseAPI.sendPost(`payment/pre_cart_split`, {
        cafe_id,
        mode,
        cart
    });
}

async function getOffertInfo(cafe_id, menu_id) {
    return await BaseAPI.sendGet(`payment/offert/${cafe_id}/${menu_id}`);
}

async function getTipsWidget(order_id, amount) {
    return await BaseAPI.sendGet(`payment/tips/${order_id}/${amount}`);
}

export default {
    getOrderWidget,
    getInsideWidget,
    getPreorderWidget,
    getCartSplits,
    getOffertInfo,
    getHotelsWidget,
    getReserveWidget,
    getTipsWidget
}